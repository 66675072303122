import {
  HiveToRedshiftDataTypeMapping,
  LanguageConstants,
} from './shared/constants';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import {
  dateTimeFormat,
  dateTimeFormatParameters,
} from './shared/constants/date-time-format-constants';

import { CyberSecurity } from './shared/models/CyberSecurity';
import { EnvironmentConfig } from './shared/models/environment';
import { Footer } from './shared/models/footer';
import { HttpClient } from '@angular/common/http';
import { Icp } from './shared/models/Icp';
import { LocalStorageService } from './local-storage.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environmentConstant } from './shared/constants/environment';
import { mdmSourceColorCodes } from './shared/constants';
import { utils_constants } from './shared/constants/ui_labels_translation_mapping';

@Injectable()
export class AppConfig {
  public config = {};
  private env: Object = null;

  // userLang = navigator.language;
  date_time_format = utils_constants.DATE_TIME_FORMAT;
  time_format: string;
  date_format: string;
  translated_format: any;
  constructor(
    private http: HttpClient,
    private _translate: TranslateService,
    private _localStorageService: LocalStorageService
  ) {}

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig() {
    return this.config;
  }

  public getConfigByKey(key: any) {
    return this.config[key];
  }

  public getConfigObject() {
    return new EnvironmentConfig(
      this.config[environmentConstant.baseUrl],
      this.config[environmentConstant.metadataSchema],
      this.config[environmentConstant.idmLogOutUrl],
      this.config[environmentConstant.defaultAvgTimeForBlock],
      this.config[environmentConstant.uiPollingInterval],
      this.config[environmentConstant.ddFileUploadPollingInterval],
      this.config[environmentConstant.paginationValues],
      this.config[environmentConstant.groupPaginationValues],
      this.config[environmentConstant.rdh_transient_cluster_hive_db_name],
      this.config[environmentConstant.should_export_audit_columns_for_redshift],
      this.config[environmentConstant.dateTimeFormat],
      this.config[environmentConstant.supportedLanguage],
      this.config[environmentConstant.languageMapping],
      this.config[environmentConstant.maxNavigationTabsCount],
      this.config[environmentConstant.defaultAvgTimeForIdataRun],
      this.config[environmentConstant.expirationCookieName],
      this.config[environmentConstant.hostDomainBlacklistingPattern],
      this.config.hasOwnProperty(environmentConstant.isMDMAdvancedSearchEnabled)
        ? this.config[environmentConstant.isMDMAdvancedSearchEnabled]
        : true,
      this.config[environmentConstant.heapAppId],
      this.config[environmentConstant.ddUploadCsvMaxFileSize],
      this.config[environmentConstant.ddUploadExcelMaxFileSize],
      this.getFooter(this.config),
      this.config.hasOwnProperty(environmentConstant.mdmAffiliationTabsList)
        ? this.config[environmentConstant.mdmAffiliationTabsList]
        : ['HCP-HCP', 'HCP-HCO', 'HCO-HCO', 'HCO-HCP'],
      this.config.hasOwnProperty(
        environmentConstant.mdmMaxAddressesToDisplayCount
      )
        ? this.config[environmentConstant.mdmMaxAddressesToDisplayCount]
        : 5,
      this.config.hasOwnProperty(environmentConstant.AWSRegionNamesList)
        ? this.config[environmentConstant.AWSRegionNamesList]
        : [],
      this.config.hasOwnProperty(
        environmentConstant.maxPrimaryAttributeToDisplayPerGroup
      )
        ? this.config[environmentConstant.maxPrimaryAttributeToDisplayPerGroup]
        : 10,
      this.config.hasOwnProperty(environmentConstant.isChinaInstance)
        ? this.config[environmentConstant.isChinaInstance]
        : false,
      this.config[environmentConstant.application],
      this.assignMDMSourceColorCodes(this.config),
      this.config.hasOwnProperty(
        environmentConstant.hiveToRedshiftDataTypeMapping
      )
        ? this.config[environmentConstant.hiveToRedshiftDataTypeMapping]
        : HiveToRedshiftDataTypeMapping,
      this.config.hasOwnProperty(environmentConstant.mdmMaxCacheAge)
        ? this.config[environmentConstant.mdmMaxCacheAge]
        : 300000,
      this.config[environmentConstant.pageSize],
      this.config.hasOwnProperty(environmentConstant.dagCompilationBuffer)
        ? this.config[environmentConstant.dagCompilationBuffer]
        : 60,
      this.config.hasOwnProperty(
        environmentConstant.defaultAverageDurationForProcessExecution
      )
        ? this.config[
            environmentConstant.defaultAverageDurationForProcessExecution
          ]
        : 30,
      this.config.hasOwnProperty(
        environmentConstant.defaultAverageDurationForFlowExecution
      )
        ? this.config[
            environmentConstant.defaultAverageDurationForFlowExecution
          ]
        : 30,
      this.config.hasOwnProperty(
        environmentConstant.blacklistedDataCenterQueryTableNames
      )
        ? this.config[environmentConstant.blacklistedDataCenterQueryTableNames]
        : ['customscripts', 'virtualenvironments'],
      this.config.hasOwnProperty(environmentConstant.supportMailId)
        ? this.config[environmentConstant.supportMailId]
        : 'support@zs.com',
      this.config.hasOwnProperty(environmentConstant.debounceInterval)
        ? this.config[environmentConstant.debounceInterval]
        : 2000,
      this.config.hasOwnProperty(
        environmentConstant.disablePaginationDropdownRecordLimit
      )
        ? this.config[environmentConstant.disablePaginationDropdownRecordLimit]
        : 10000000,
      this.config.hasOwnProperty(
        environmentConstant.showLinearPaginationDropdownThreshold
      )
        ? this.config[environmentConstant.showLinearPaginationDropdownThreshold]
        : 50000,
      this.config.hasOwnProperty(
        environmentConstant.defaultPaginationDropdownLimit
      )
        ? this.config[environmentConstant.defaultPaginationDropdownLimit]
        : 25
    );
  }

  private assignMDMSourceColorCodes(config): object {
    return config.hasOwnProperty(environmentConstant.mdmSourceColorCodes)
      ? this.createMDMSourceColorCodesObject(config)
      : mdmSourceColorCodes;
  }

  private createMDMSourceColorCodesObject(config): object {
    const colorobj = config[environmentConstant.mdmSourceColorCodes];
    for (const source in mdmSourceColorCodes) {
      if (colorobj[source] === undefined) {
        colorobj[source] = mdmSourceColorCodes[source];
      }
    }
    return colorobj;
  }

  private getFooter(config): Footer {
    let footer: Footer;
    config.hasOwnProperty(environmentConstant.footer)
      ? (footer = new Footer(
          config[environmentConstant.footer].hasOwnProperty(
            environmentConstant.termsOfUseUrl
          )
            ? config[environmentConstant.footer][
                environmentConstant.termsOfUseUrl
              ]
            : 'http://www.zsservices.com/hosted/terms.htm',
          config[environmentConstant.footer].hasOwnProperty(
            environmentConstant.privacyPolicyUrl
          )
            ? config[environmentConstant.footer][
                environmentConstant.privacyPolicyUrl
              ]
            : 'http://www.zsservices.com/hosted/privacy.htm',
          config[environmentConstant.footer][environmentConstant.logoName],
          config[environmentConstant.footer].hasOwnProperty(
            environmentConstant.cyberSecurity
          )
            ? new CyberSecurity(
                config[environmentConstant.footer][
                  environmentConstant.cyberSecurity
                ][environmentConstant.cyberSecurityNumber],
                config[environmentConstant.footer][
                  environmentConstant.cyberSecurity
                ][environmentConstant.cyberSecurityUrl]
              )
            : null,
          config[environmentConstant.footer].hasOwnProperty(
            environmentConstant.icp
          )
            ? new Icp(
                config[environmentConstant.footer][environmentConstant.icp][
                  environmentConstant.icpNumber
                ],
                config[environmentConstant.footer][environmentConstant.icp][
                  environmentConstant.icpUrl
                ],
                config[environmentConstant.footer][environmentConstant.icp][
                  environmentConstant.icpSuffix
                ]
              )
            : null
        ))
      : (footer = new Footer(
          'http://www.zsservices.com/hosted/terms.htm',
          'http://www.zsservices.com/hosted/privacy.htm'
        ));
    return footer;
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  public load() {
    return new Promise((resolve, reject) => {
      this.http
        .get('./assets/env/env.json')
        .pipe(map((res) => res))
        .pipe(
          catchError((error: any): any => {
            resolve(true);
            return Observable.throw(error.json().error || 'Server error');
          })
        )
        .subscribe((envResponse) => {
          this.env = envResponse;
          let request: any = null;
          switch (envResponse['env']) {
            case 'development':
              {
                request = this.http.get(
                  './assets/env/env.' + envResponse['env'] + '.json'
                );
              }
              break;
            case 'production':
              {
                request = this.http.get(
                  './assets/env/env.' + envResponse['env'] + '.json'
                );
              }
              break;
            case 'bi_prod':
              {
                request = this.http.get(
                  './assets/env/env.' + envResponse['env'] + '.json'
                );
              }
              break;
            case 'stage':
              {
                request = this.http.get(
                  './assets/env/env.' + envResponse['env'] + '.json'
                );
              }
              break;
            case 'local':
              {
                request = this.http.get(
                  './assets/env/env.' + envResponse['env'] + '.json'
                );
              }
              break;

            case 'default':
              {
                resolve(true);
              }
              break;
          }

          if (request) {
            request
              .pipe(map((res) => res || {}))
              .pipe(
                catchError((error: any) => {
                  resolve(error);
                  return Observable.throw(error.json().error || 'Server error');
                })
              )
              .subscribe((responseData: any) => {
                this.config = responseData;
                this.config[environmentConstant.dateTimeFormat] = {};

                this._translate.setDefaultLang(
                  LanguageConstants.DEFAULT_LANGUAGE
                );

                let browserLang = navigator.language;
                if (
                  JSON.parse(this._localStorageService.getUserLanguageDetails())
                ) {
                  browserLang = JSON.parse(
                    this._localStorageService.getUserLanguageDetails()
                  );
                }

                let selectedLanguage = browserLang;
                if (
                  this.config[environmentConstant.supportedLanguage].indexOf(
                    browserLang
                  ) !== -1
                ) {
                  this._translate.use(browserLang);
                } else if (
                  this._translate.getBrowserLang() in
                  this.config[environmentConstant.languageMapping]
                ) {
                  browserLang =
                    this.config[environmentConstant.languageMapping][
                      this._translate.getBrowserLang()
                    ];
                  this._translate.use(browserLang);
                  selectedLanguage = browserLang;
                } else {
                  browserLang = LanguageConstants.DEFAULT_LANGUAGE;
                  this._translate.use(browserLang);
                  selectedLanguage = browserLang;
                }

                const translated_labels = this._translate.get([
                  this.date_time_format.TIME_FORMATS,
                  this.date_time_format.DATE_FORMATS,
                ]);

                const $this = this;
                const myObserver = {
                  next: function (translated_labels) {
                    $this.time_format =
                      translated_labels[$this.date_time_format.TIME_FORMATS];
                    $this.date_format =
                      translated_labels[$this.date_time_format.DATE_FORMATS];
                  },
                  error: function (err) {
                    console.log('Observer got an error: ' + err);
                    if ($this.time_format === undefined) {
                      $this.config[environmentConstant.dateTimeFormat][
                        dateTimeFormatParameters.TIME_FORMATS
                      ] = dateTimeFormat[dateTimeFormatParameters.TIME_FORMATS];
                    }
                    if ($this.date_format === undefined) {
                      $this.config[environmentConstant.dateTimeFormat][
                        dateTimeFormatParameters.DATE_FORMATS
                      ] = dateTimeFormat[dateTimeFormatParameters.DATE_FORMATS];
                    }
                    resolve(true);
                  },
                  complete: function () {
                    console.log('Observer got a complete notification');
                    if (
                      $this.time_format !== undefined &&
                      $this.date_format !== undefined
                    ) {
                      $this.config[environmentConstant.dateTimeFormat][
                        dateTimeFormatParameters.TIME_FORMATS
                      ] = $this.time_format;
                      $this.config[environmentConstant.dateTimeFormat][
                        dateTimeFormatParameters.DATE_FORMATS
                      ] = $this.date_format;
                    }
                    resolve(true);
                  },
                };
                translated_labels.subscribe(myObserver);
              });
          } else {
            resolve(true);
          }
        });
    });
  }
}
